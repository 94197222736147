import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isEmpty } from 'ramda'
import { media, theme } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'
import {
  CargoTrailer,
  CargoHazardousTrailer,
  CargoIndustrial,
  CargoSelfDrive,
  CargoIsoContainer,
  CargoReeferContainer,
  CargoMobileMachine,
  CargoAutomotive,
  CargoReeferTrailer,
  BulkLiquid,
} from '@dfds-ui/icons'
import { Headline } from '@dfds-ui/react-components'

import { PageLayout } from '../components/Layout'
import Hero from '../components/Hero/Hero'
import { PromoBlock } from '../components/PromoBlock'
import { FlexCard } from '../components/Card'
import { FreightShippingSchedule } from '../components/FreightShippingSchedule'
import { InfoBlock } from '../components/InfoBlock'
import { IconBlockList } from '../components/IconBlockList'
import { MapLayout } from '../components/Map'
import { TwitterWidget } from '../components/TwitterTimeline'
import { HazardousCargo } from '../components/HazardousCargo'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { BafChargeBlock } from '../components/BafChargeBlock'
import { gridMap, isContentfulType } from '../utils'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      width: calc(33.33% - 26.66px);

      &[md="12"], &[lg="12"] {
        width: 100%;
      }
      &[md="8"], &[lg="8"] {
        width: calc(66.66% - 20px);
      }
      &[md="6"], &[lg="6"] {
        width: calc(50% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
      &[md="3"], &[lg="3"] {
        width: calc(25% - 30px);
      }
    `}
  }
`
const WrapperEightFour = styled(Wrapper)`
  > div {
    ${media.greaterThan('xl')`
      &:nth-of-type(1) {
        width: calc(66.66% - 20px);
      }
      &:nth-of-type(2) {
        width: calc(33.33% - 26.66px);
      }
    `}
  }
`

const MultiroutePage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    iconRow,
    freightShippingSchedule,
    promoLayoutsCollection,
    twitterFeed,
    mapsAndContactBlocksCollection,
    ourServicesBlocksCollection,
    whileOnBoardTextAndImageBlock,
    serviceChargeBlocksCollection,
    hazardousLoadsHazardousCargoBox,
    abnormalLoadsTextSection,
    freightSupportPromoLayout,
    newCustomerTextAndImageBlock,
    faqSection,
  } = content.page
  const iconTypes = {
    trailer: CargoTrailer,
    hazardous_cargo: CargoHazardousTrailer,
    industrial_cargo: CargoIndustrial,
    self_drive: CargoSelfDrive,
    iso_container: CargoIsoContainer,
    reefer_container: CargoReeferContainer,
    mobile_machine: CargoMobileMachine,
    automotive: CargoAutomotive,
    reefer_trailer: CargoReeferTrailer,
    bulk_liquid: BulkLiquid,
  }
  const { t } = useTranslation()
  const icons = iconRow.iconsCollection.items.map((item) => {
    const IconToUse = styled(iconTypes[item.iconId])`
      color: ${(props) => props.theme['icon-color']};
      fill: ${theme.colors.surface.primary};
    `

    return {
      icon: null,
      ComponentIcon: (
        <IconToUse width={'100px'} height={'100px'} viewBox={'0 0 32 25'} />
      ),
      title: item.iconText,
    }
  })

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} />
          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      <FlexCard noPadding={true} noSpacing={true}>
        <IconBlockList icons={icons} iconWidth={80} hasBorders={false} />
      </FlexCard>
      <FreightShippingSchedule {...freightShippingSchedule} />
      <Wrapper>
        {promoLayoutsCollection.items.map((item, index) => (
          <PromoBlock
            promoContent={item.promoContent}
            backgroundColor={item.backgroundColor}
            key={index}
            width={{ md: 4 }}
          />
        ))}
        <TwitterWidget {...twitterFeed} />
      </Wrapper>

      {!isEmpty(ourServicesBlocksCollection.items) && (
        <>
          <Headline
            as={'h2'}
            css={css`
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {t('ROUTE-OUR-SERVICES')}
          </Headline>
          <Wrapper>
            {ourServicesBlocksCollection.items.map((item) => {
              return (
                <React.Fragment key={item.sys.id}>
                  {isContentfulType(
                    item.__typename,
                    'contentful_TextAndMediaLayout',
                  ) ? (
                    <TextAndMediaLayout
                      mediaWidth={{ md: 6 }}
                      reverse
                      {...item}
                    />
                  ) : (
                    <InfoBlock {...item} />
                  )}
                </React.Fragment>
              )
            })}
          </Wrapper>
        </>
      )}

      <Headline
        as={'h2'}
        css={css`
          margin-left: 20px;
          margin-right: 20px;

          ${media.greaterThan('m')`
            margin-left: 0;
            margin-right: 0;
          `}
        `}
      >
        {t('ROUTE-CONTACT-US')}
      </Headline>
      {mapsAndContactBlocksCollection &&
        mapsAndContactBlocksCollection.items.map((item) => {
          return (
            <React.Fragment key={item.sys.id}>
              {isContentfulType(
                item.__typename,
                'contentful_TextAndMediaLayout',
              ) ? (
                <TextAndMediaLayout {...item} />
              ) : (
                <MapLayout {...item} />
              )}
            </React.Fragment>
          )
        })}

      {whileOnBoardTextAndImageBlock && (
        <>
          <Headline
            as={'h2'}
            css={css`
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {t('ROUTE-WHILE-ON-BOARD')}
          </Headline>
          <TextAndMediaLayout
            mediaWidth={{ md: 8 }}
            {...whileOnBoardTextAndImageBlock}
          />
        </>
      )}
      {serviceChargeBlocksCollection && (
        <>
          <Headline
            as={'h2'}
            css={css`
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
                margin-left: 0;
                margin-right: 0;
              `}
            `}
          >
            {t('ROUTE-SERVICE-CHARGES')}
          </Headline>
          <Wrapper>
            {serviceChargeBlocksCollection.items.map((item) => {
              return (
                <React.Fragment key={item.sys.id}>
                  {isContentfulType(
                    item.__typename,
                    'contentful_BafChargeBlock',
                  ) ? (
                    <BafChargeBlock {...item} />
                  ) : (
                    <InfoBlock {...item} />
                  )}
                </React.Fragment>
              )
            })}
          </Wrapper>
        </>
      )}
      <Headline
        as={'h2'}
        css={css`
          margin-left: 20px;
          margin-right: 20px;

          ${media.greaterThan('m')`
            margin-left: 0;
            margin-right: 0;
          `}
        `}
      >
        {t('ROUTE-HAZARDOUS-ABNORMAL-LOADS')}
      </Headline>
      <HazardousCargo {...hazardousLoadsHazardousCargoBox} />
      <WrapperEightFour>
        <TextAndMediaLayout {...abnormalLoadsTextSection} />
        <PromoBlock
          promoContent={freightSupportPromoLayout.promoContent}
          backgroundColor={freightSupportPromoLayout.backgroundColor}
          blockWidth={gridMap[freightSupportPromoLayout.blockWidth]}
        />
      </WrapperEightFour>
      <TextAndMediaLayout {...newCustomerTextAndImageBlock} />
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query MultiroutePage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: multiroutePage(id: $id, locale: $locale, preview: $preview) {
        hero: heroLayout {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        iconRow {
          iconsCollection(limit: 8) {
            items {
              iconId
              iconText
              sortWeight
            }
          }
        }
        freightShippingSchedule {
          ...FreightShippingSchedule
        }
        promoLayoutsCollection(limit: 2) {
          items {
            ...PromoBlock
          }
        }
        twitterFeed {
          ...TwitterFeed
        }
        ourServicesBlocksCollection {
          items {
            __typename
            ...InfoBlock
            ...TextAndMediaLayout
          }
        }
        mapsAndContactBlocksCollection {
          items {
            __typename
            ...TextAndMediaLayout
            ...MapLayout
          }
        }
        whileOnBoardTextAndImageBlock {
          ...TextAndMediaLayout
        }
        serviceChargeBlocksCollection(limit: 3) {
          items {
            __typename
            ...BafChargeBlock
            ...InfoBlock
          }
        }
        hazardousLoadsHazardousCargoBox {
          ...HazardousCargo
        }
        abnormalLoadsTextSection {
          ...PageContentTextSection
        }
        freightSupportPromoLayout {
          ...PromoBlock
        }
        newCustomerTextAndImageBlock {
          ...TextAndMediaLayout
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default MultiroutePage
